.steps-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0 5rem;

}
.steps-main-div-b {
    text-align: center;
}

.steps-image {
    width: 85%;
}

.steps-main-div-a-heading {
    font-size: 40px;
    font-weight: 700;
}
/* .clients{
    border: 2px solid red;
} */

.steps-main-div-a-number {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 19px;
    color: white;
    background-color: var(--Secondary-color);
}

.steps-main-div-a-number-div {
    width: fit-content;
    padding: 0.9rem 1.2rem;
    margin: 3rem 0;
    border-radius: 20px;
    box-shadow: 0 20px 50px var(--light-primary-color);
}
.how-it-work-2-step-div{
    flex-direction: row-reverse;
}

.how-it-work-div-b{
    display: flex;
    gap: 1rem;
    margin: 8rem 0 ;
    justify-content: center;

}
.how-it-work-div-c{
    width: 80%;
    margin: 5rem 0;
}
.how-it-work-div-d{
    display: flex;
    gap: 10px;
   justify-content: center;
    flex-wrap: wrap;
    margin: 7rem 0;
}
.how-it-work-div-e-btn{
    margin: 1rem !important;
    padding: 0.6rem 1rem !important;
}
.how-it-work-steps-heading-row{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3rem 0;
}
.how-it-work-steps-heading{
    width: 70% !important;
margin-left: 6rem !important;
}

@media(max-width:992px) {
    .steps-main-div {
        flex-direction: column-reverse;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 2rem 0 5rem;
        gap: 0;

    }

    .how-it-work-image-seciton{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
     }
    
    .steps-main-div-b {
        width: 100%;
    }

    .steps-image {
        width: 75% !important;
    }

    .steps-main-div-a-heading {
        font-size: 34px;
    }

    .steps-main-div-a {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .steps-main-div-a-number-div {
        padding: 0.9rem 1.2rem;
        margin: 1.7rem 0;
    }
    .how-it-work-div-b{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 4rem 0 5rem ;
        
    }
    .how-it-work-div-c{
        margin: 4rem 0;
        width: 100%;
    }
    .how-it-work-div-d{
        margin: 4rem 0;
    }
    
}



@media(max-width:588px){
    .how-it-work-steps-heading-row{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 3rem 0
    }
    .how-it-work-steps-heading{
        width: 100% !important;
    margin-left: 0rem !important;
    }
}

@media(max-width:560px){
    .steps-main-div {
        margin: 1rem 0 3rem !important;
        gap: 0;

    }
    .steps-main-div-a-heading {
        font-size: 28px;
    }
    .steps-main-div-a-number-div {
        padding: 0.5rem 0.7rem;
        margin: 0.8rem 0;
    }

    .steps-main-div-a-number {
        padding: 0.2rem 0.7rem;    
    }
    .how-it-work-div-b{
        margin: 3rem 0 4rem ;
        
    }
    .how-it-work-div-c{
        margin: 2.6rem 0;
        width: 100%;
    }
    .how-it-work-div-d{
        margin: 4rem 0;
    }
    .how-it-work-div-e-btn{
        margin: 1rem !important;
        font-size: 15px !important;
        padding: 0.5rem 0.7rem !important;
    }
}



@media(max-width:451px){
    .how-it-work-dashboard{
       width: 90% !important; 
     }
}


@media(max-width:520px){
    
.how-it-work-div-d{
    display: flex;
    gap: 10px;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap; 
    margin: 7rem 0;
}

}



@media(max-width:412px){
    .steps-main-div-a-heading {
        font-size: 22px;
    }
    .how-it-work-div-c{
        margin: 2rem 0;
        width: 100%;
    }
}