
.term-condition-main{
    margin: 1rem auto  5rem;
}


@media(max-width:992px){
    .term-condition-main{
        margin: 1rem auto  3rem;
    }
   
}



@media(max-width:550px){
   
    .term-condition-main{
        margin: 1rem auto  2.4rem;
    }
    
}