.user-entity-section {
    border: 1px solid var(--primary-color);
    background-color: white !important;
    height: 5rem !important;
    padding: 0.6rem !important;
    margin-bottom: 1rem !important;
    align-items: center !important;
}

.user-entity {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    margin: 2rem 0;
}

.entity-form {
    padding: 1rem;
    width: 50%;
    background-color: #FFFFFF;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: left !important;
}
.buisness-name-gererator-div{

    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem !important;
}
.industry-guide{
height: 19rem;
}
.llc-state-information{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
}
.llc-state-information-section{
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 20px;
    background-color: var( --light-primary-color);
    transition: 0.5s all;
}
.llc-state-information-section:hover{
    
    background-color: var(--light-Secondary-color );
}
.llc-state-information-section:hover
.llc-state-information-text
{
    color: var( --Secondary-color);
}
.llc-state-information-text{
    font-weight: bolder;
    color: var(--primary-color);
    transition: 0.5s all;

    
}