:root{
    --primary-color:#03045e ;
    --logo-color:#1A7FC3 ;
    --light-primary-color: #C6C7FD;

    /* --Secondary-color: #f72585; */
    --Secondary-color: #FAAF40;

    --light-Secondary-color: #FFDCB5;
}

  
@font-face {
    font-family: "Avenir", sans-serif;
    src: url("./font/AvenirLTStd-Black.otf") format("woff"),
    url("./font/AvenirLTStd-Black.otf")  format("woff2"),
    url("./font/AvenirLTStd-Black.otf")  format("svg");
  }
  
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    margin-top: 7rem !important;
    font-family: "Avenir", sans-serif;
    font-weight: 400;
    overflow-x: hidden  !important;
    
    color: #393E46!important;
}
a{
    color: #03045e;
    text-decoration: none !important;
}
dt{
    line-height: 20px
}
p{
    margin: 0 !important;
    line-height: 20px
}


input ,
select,
option{
    border: 1px solid var(--primary-color) !important;
    padding: 6px 10px !important;
    /* width: 100% !important; */
    opacity: 0.5 !important;
    border-radius: 20px !important;
    box-shadow: none !important;
}
.textarea{
    border: 1px solid var(--primary-color) !important;
    padding: 6px 10px !important;
    
    border-radius: 40px !important;
}
h1,h2,h3,h4,h5,h6{
    margin: 0 !important;
    color: #023e8a !important;
    
}
input{
    outline: none !important;
}
textarea:focus{
    outline: none !important;
}

.whatsapp-contact{
    position: fixed;
    right: 20px;
    bottom:20px;
    font-size: 2.5rem;
    z-index: 2;
    color: #FFFFFF;
    padding:  0 0.8rem;
    border-radius: 50%;
    transition: 0.6s all;
    background-color: green;
  } 
  .whatsapp-contact:hover {
    color: green;
    border: 0.2px solid green;
    background-color: #FFFFFF;
    }







.Blog-page{
    background-color: #FFFFFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    padding-bottom: 1rem;
}

    .blog-main{
        display: flex; 
        flex-flow: column;
    }
    .blog-card-grid{
        display: grid;
        grid-template-columns: repeat(3,1fr) ;
        grid-gap: 1rem;
        grid-row-gap: 1rem;
      }

.blog-heading{
    color: var(--Secondary-color) !important;
    margin: 10px auto !important;
    font-weight: bold !important
}
.blog-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}
.blog-card:hover {
    transform: translateY(-5px);
}
.blog-bg-image{
    width: 100%;
    height: auto;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.blog-image {
    width:100%;
    height:  auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.blog-inner-text  {
    padding: 1rem;
   }
.blog-inner-text >h5  {
    transition:  0.5s ;

   }
   .blog-card:hover .blog-inner-text >h5  {
    color: var(--Secondary-color) !important;
   }
.blog-category{
    padding: 2px 8px ;
    font-size: 12px ;
    background-color: var(--Secondary-color);
    color: #FFFFFF;
    border-radius: 15px;
   width: fit-content;

}
@media (max-width: 992px) {
    .blog-card-grid{
        display: grid;
        grid-template-columns: repeat(2,1fr) ;
        grid-gap: 1rem;
        grid-row-gap: 1rem;
      }
}
    
    @media (max-width: 767px) {
        .blog-card-grid{
            display: grid;
            grid-template-columns: repeat(1,1fr) ;
            grid-gap: 1rem;
            grid-row-gap: 1rem;
          }
    }