.login{
    display: flex;
    height: 100vh;
}
.login-col-a{
    background-color: white;
   width: 40%;
   padding:2rem 3rem ;
}

.login-back-icon{
    background-color: var(--primary-color);
    color: white;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.login-col-a-col{
    margin: 5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
}
.login-col-b{
    background-color: var(--light-primary-color);  width: 60%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

}
.login-email-label{
    width: 100%;
}
.login-email-input{
    border: 1px solid var(--primary-color) !important;
    padding: 6px 10px;
    width: 100% !important;
    opacity: 0.5;
    border-radius: 20px;

}
.login-btn{
    padding: 10px 2.5rem !important;
}
.login-image{
    width: 26rem;
}


@media(max-width:1265px){
    .login{
       height: auto;
   }
}

@media(max-width:767px){
    .login{
         display: flex;
        flex-direction: column;
    }

    .login-col-b{
       width: 100%;
    }
    .login-col-a{
        width: 100%;
    }
    .login-col-a-col{
        margin: 0rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        flex-direction: column;
    }
    .login-image{
        width: 22rem;
    }
    .login-col-b{
      padding:2rem 0 ;
    
    }
}

@media(max-width:550px){
    
    .login-image{
        width: 18rem;
    }
    .login-btn{
        font-size: 14px !important;
        padding: 5px 1.5rem !important;
    }
    
}

@media(max-width:400px){
    
    .login-image{
        width: 15rem;
    }
    
}
