.footer-section-a {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

/* ////////////////////////////////////////////  footer b */
.footer-b {

    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    gap: 12px;
    margin: 2rem 0;
}

.footer-b-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
    transition: all 0.5s;

}

.footer-b-column-heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    opacity: 0.7;
    color: var(--primary-color) !important;
    text-transform: uppercase;
    margin-bottom: 24px;
}

.footer-b-text {
    opacity: 0.7;
    color: #03045e !important;
    font-size: 14.7px;
    transition: all 0.3s;
    line-height: 18px;
    /* text-transform: capitalize; */
}

.footer-b-text:hover {
    opacity: 0.9;
    color: var(--Secondary-color) !important;
    transform: translate(3px);
}

.footer-b-names {
    display: flex;
    flex-direction: column;
}
.footer-b-address{
    font-size: 14.7px;
    color: #03045e !important;
}
.hide {
    display: none;
}

.down-icon {
    border: none !important;
    background-color: white;
    display: none;
    transition: all 0.5s;
}

.down-icon i {
    outline: none !important;
    border: none !important;
    color: var(--primary-color);
}

.down-icon i:hover {
    color: var(--Secondary-color);
}

/* ///////////////////////////////////////////////////////////  Footer-c-div */
.footer-c{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 0;
}
.Footer-c-div {
    display: flex;
    gap: 6px;
}
.footer-c-brand-icon-a ,.footer-c-brand-icon-b{
    font-size: 1.5rem ;
    padding:5px;
}
.footer-c-brand-icon-a{
   border: 1px solid var(--primary-color);
    color: var(--Secondary-color) !important;
    transition: all 1s;
}
.footer-c-brand-icon-a:hover{
    border: 1px solid var(--Secondary-color);
     color: var(--primary-color) !important;
 }

.footer-c-brand-icon-b{
    border: 1px solid var(--Secondary-color);
    transition: all 1s;
    color: var(--primary-color) !important;
}
.footer-c-brand-icon-b:hover{
    border: 1px solid var(--primary-color);
    color: var(--Secondary-color) !important;
}